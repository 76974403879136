<template lang="pug">
  .ota-tooltip
    FaIcon.ota-tooltip-icon(
      :id="tooltipId"
      :class="iconColor"
      icon="exclamation-triangle"
    )
    BTooltip(
      custom-class="ota-tooltip-body"
      :target="tooltipId"
      placement="right"
    )
      .tooltip-title {{ $t("ota_management.plan_list.status.tooltip.not_all_ota_published") }}
      .tooltip-row(v-for="(published, otaId) in publishedByOta")
        .ota-name
          span {{ otaNameById[otaId] }}
        .status
          span {{ otaStatusText(published) }}
</template>

<script>
  const ICON_COLORS = ["yellow", "purple"]

  // misc
  import { includes } from "lodash-es"
  import { randomKey } from "@/helpers/common"
  import { mapTranslatedOtaNames } from "@/helpers/common"

  export default {
    props: {
      otas: {
        type: Array,
        default: () => new Array()
      },

      iconColor: {
        type: String,
        default: "yellow",
        validator: value => includes(ICON_COLORS, value)
      },

      publishedByOta: {
        type: Object,
        default: () => new Object()
      }
    },

    computed: {
      tooltipId() {
        return `ota-tooltip-${randomKey()}`
      },

      otaNameById() {
        return mapTranslatedOtaNames(this.otas)
      }
    },

    methods: {
      tooltipRowText(otaId, published) {
        return `${this.otaNameById[otaId]}: ${this.otaStatusText(published)}`
      },

      otaStatusText(published) {
        const suffix = published ? "published" : "preparing"
        const translatedStatus = this.$t(`ota_management.plan_list.status.${suffix}`)
        return `: ${translatedStatus}`
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .ota-tooltip
    &-icon
      margin-left: 10px
      margin-top: 11px
      height: 16px

      &.yellow
        color: $default-yellow

      &.purple
        color: $default-purple


    &-body
      ::v-deep
        .tooltip-inner
          padding: 20px
          max-width: 230px

          .tooltip-title
            text-align: left
          .tooltip-row
            margin-top: 5px
            padding: 0 10px
            display: flex
            justify-content: space-between
            span
              text-align: left
</style>
